* {
    box-sizing: border-box;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

*::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

html {
    scroll-behavior: smooth;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    background: rgb(63, 94, 251);
    background: radial-gradient(circle, rgba(63, 94, 251, 1) 0%, rgba(252, 70, 107, 1) 100%) !important;
}

@media only screen and (max-width: 768px) {
    body {
        background: #292727 !important;
    }
}

.content-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
}

.content-embed {
    position: relative;
    background: #292727;
    border-radius: 37.5px;
    width: 375px;
    height: 825px;
    overflow: scroll !important;
}

.no-cursor {
    cursor: none !important;
}

canvas {
    position: absolute;
    z-index: 1;
}

a {
    :visited {
        text-decoration: none !important;
        color: black !important;
    }
}
